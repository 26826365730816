import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = (props) => {
  return (
    <Layout>
      <SEO title="About" />
      <div className="prose mx-auto">
        <h1>About</h1>
        <p>
          Welcome to <b>Interviews<span className="text-blue-500">.school</span></b>! The purpose of this website is to help you prepare for software engineering interviews.
        </p>
        <p>
          Interviews.school is created by <a href='http://adilet.org' target='_blank' rel="nofollow noopener noreferrer">Adilet Zhaxybay</a>.
          I am an ex-Google software engineer. A couple of years ago, I also had job offers from Google, Facebook, Uber, and Amazon. You can read more about my interview preparation journey and background <a href="http://adilet.org/blog/your-ultimate-guide-to-interview-preparation/" target='_blank' rel="nofollow noopener noreferrer">on my personal website.</a>
        </p>
        <h2>
          Contact & Feedback
        </h2>
        <p>
          To contact me, email me on <a href='mailto:adilet.zxy@gmail.com' target='_blank' rel="nofollow noopener noreferrer">adilet.zxy@gmail.com</a>.
        </p>
        <p>
          Have feedback about the website? Found a mistake? Got an idea of what to add next? Just want to say hi? Feel free to email me, I will be happy to hear from you!
        </p>
        <p>
          You can also leave quick anonymous feedback using this <a href="https://forms.gle/TXt5XxeX37gVCKXf9" target='_blank' rel="nofollow noopener noreferrer">Google Form</a>. Thanks!
        </p>
        <h2>
          Support Interviews.school
        </h2>
        <p>
          Currently, the best thing you can do to support Interviews.school is to share it with your friends. Who could use a detailed interview preparation guide?
        </p>
        <p>
          And I would love to hear your feedback – what can I improve?
        </p>
        {/* <div className="hidden sm:flex align-baseline">
          <div>
            Lastly, you can buy me a coffee:
          </div>
          <div>
            <a href='https://ko-fi.com/I3I21PQGN' target='_blank' rel="nofollow noopener noreferrer"><img height='36' className="ml-3" style={{marginTop: "-0.25em", border:'0px', height:'36px'}} src='https://cdn.ko-fi.com/cdn/kofi1.png?v=2' border='0' alt='Buy Me a Coffee at ko-fi.com'/></a>
          </div>
        </div> */}
        <div className="align-baseline">
          <p>
            Lastly, you can buy me a coffee:
          </p>
          <div>
            <a href='https://ko-fi.com/I3I21PQGN' target='_blank' rel="nofollow noopener noreferrer"><img height='36' style={{marginTop: "0em", border:'0px', height:'36px'}} src='https://cdn.ko-fi.com/cdn/kofi1.png?v=2' border='0' alt='Buy Me a Coffee at ko-fi.com'/></a>
          </div>
        </div>
        <h2>
          Follow us
        </h2>
        <p>
          For the updates, follow Interviews.school on <a href="https://twitter.com/InterviewsSch" target="_blank" rel="nofollow noopener noreferrer">Twitter</a> or <a href="https://t.me/interviewsschool" target="_blank" rel="nofollow noopener noreferrer">Telegram</a>.
        </p>
        <p>
          Thank you for using <b>Interviews<span className="text-blue-500">.school</span></b>!
        </p>
        <center>
          <img className="w-3/5 md:w-3/5 lg:w-3/5 mt-4 md:mt-10 lg:mt-10 transition ease-in-out duration-300 transform hover:scale-105" src="/about.svg" />
        </center>
      </div>
    </Layout>
  )
}

export default AboutPage
